import PropTypes from "prop-types"
import React from "react"

import Logo from "../images/redkiwi_logo_kiwi.svg"
const Header = () => (
    <div className='header'>
        <img src={Logo} alt='Logo' className='headerLogo' />
        <p className="headerText">CREATE DIGITAL IMPACT</p>
    </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
