import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import { IndexLayout } from "../components/layout"
import SEO from "../components/seo"
import Menu from "../components/menu"
import Header from "../components/header"
import CustomTooltip from "../components/tooltip"

export const indexQuery = graphql`
  query indexData {
    markdownRemark(frontmatter: {title: {eq: "index"}}) {
      html
    }
  }
`

const IndexPage = ({ data }) => {
  const post = data.markdownRemark
  const [className, setClass] = useState('menu')
  const handleScroll = () => {
    const header = document.getElementsByClassName('header')[0]
    const content = document.getElementsByClassName('content')[0]
    const sticky = header.clientHeight

    if (window.pageYOffset > sticky) {
      setClass('menu sticky')
      content.style.marginTop = "150px"
    } else {
      setClass('menu')
      content.style.marginTop = "100px"
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return function cleanup() {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <IndexLayout>
      <SEO title="Home" />
      <Header />
      <Menu className={className} />
      <div className='content'>
        <div className='intro' dangerouslySetInnerHTML={{ __html: post.html }} />
        {/* Todo --- Quick access menu */}
      </div>
    </IndexLayout>
  )
}



export default IndexPage
